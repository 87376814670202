<template>
  <div>
    <b-row class="bg-gray-100 p-3">
      <b-col>
        <div class="mb-1"><b-btn variant="success" size="sm" class="font-base">concept</b-btn> - all ok</div>
        <div><b-btn variant="danger" size="sm" class="font-base">concept</b-btn> - missing value or a new concept</div>
      </b-col>
      <b-col>
        <div><b-badge class="font-base" variant="info">missing</b-badge> - not found in the source code, need to be checked and removed from concepts</div>
        <div><b-badge class="font-base" variant="success">variable</b-badge> - variable concepts dynamically assigned</div>
        <div><b-badge class="font-base" variant="danger">delete</b-badge> - will be removed on import</div>
      </b-col>
    </b-row>

    <b-row class="mb-4 bg-gray-300 p-3">
      <b-col>
        1. Select branch
        <b-dropdown id="dropdown-1" v-model="selectedBranch" variant="primary" :text="selectedBranch && selectedBranch.name || '... select branch ...'" class="">
          <b-dropdown-item v-for="b in branches" :key="'b' + b.name" @click="selectBranch(b)">{{ b.name }}</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col>
        <b-form-file
          v-model="magnoliaFile"
          :state="Boolean(magnoliaFile)"
          placeholder="2. Select export file from Magnolia ..."
          drop-placeholder="Drop file here..."
          @input="loadMagnoliaFile()"
        />
      </b-col>
      <b-col v-if="error">{{ error }}</b-col>
      <b-col v-if="fileType">{{ fileType }}</b-col>
      <b-col class="text-right">
        3: Export concepts
        <b-btn variant="primary" :disabled="!selectedBranch" @click="exportToFile">Export to Magnolia</b-btn>
      </b-col>
    </b-row>
    <div class="p-3">
      <b-row>
        <b-col>
          <Teatree :roots="tree">
            <template slot="node-toggle" slot-scope="{ node }">
              <NodeToggle :node="node" />
            </template>
            <template slot="node-name" slot-scope="{ node }">
              <b-btn size="sm" block :variant="(node.data === selectedNode ? 'outline-' : '') + node.data.variant" @click="selectNode(node)">
                <b-row class="w-100 font-base">
                  <b-col class="text-left"><strong>{{ node.name }}</strong></b-col>
                  <b-col class="col-items">{{ node.children.length > 0 ? node.children.length + ' items' : '' }}</b-col>
                  <b-col class="col-missing">
                    <b-badge v-if="node.data.variable" class="font-base mr-2" variant="success">variable</b-badge>
                    <b-badge v-else-if="node.data.missing" class="font-base mr-2" variant="info">misisng</b-badge>
                    <b-badge v-else-if="node.data.containMissing" class="font-base mr-2" variant="info">missing keys</b-badge>
                    <b-badge v-if="node.data.delete" class="font-base" variant="danger">delete</b-badge>
                  </b-col>
                </b-row>
              </b-btn>
            </template>
          </Teatree>
        </b-col>
        <b-col>
          <div v-if="selectedNode.cp" class="sticky pt-2">
            <b-row class="mb-2">
              <b-col><p><strong>{{ selectedNode.cp }}</strong><span v-if="selectedNode.ck"> - {{ selectedNode.ck }}</span></p></b-col>
              <b-col class="text-right">
                <b-btn :variant="selectedNode.delete ? 'danger' : 'primary'" @click="deleteSelectedNode">{{ selectedNode.delete ? 'Undelete' : 'Delete' }}</b-btn>
              </b-col>
            </b-row>

            <h2><strong>NA</strong> - North America</h2>

            <b-form-group
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="7"
              label="English"
              label-for="input-horizontal"
              class="mb-1"
            >
              <div class="d-flex">
                <b-form-input v-model="selectedNode.content" size="sm" :state="selectedNode.content !== ''" @change="calculateTree" />
                <b-form-checkbox v-model="selectedNode.type"
                                 class="primary ml-3"
                                 unchecked-value="PlainText"
                                 value="RichText"
                                 size="lg"
                                 switch
                >
                  <b class="text-small">{{ selectedNode.type }}</b>
                </b-form-checkbox>
              </div>
            </b-form-group>


            <div v-if="!selectedNode['ck']" class="d-flex">
              <b-input v-model="newConceptName" placeholder="concept name" /><b-btn variant="primary" class="ml-2 text-nowrap" @click="addNewConcept">Add new concept</b-btn>
            </div>
            <div v-if="selectedNode.cp && concepts && concepts[selectedNode.cp]">
              <hr>
              <p>Location in the source code:</p>
              <template v-if="selectedNode.ck && concepts[selectedNode.cp].concepts[selectedNode.ck] && concepts[selectedNode.cp].concepts[selectedNode.ck].locations">
                <div v-for="(loc, i) of concepts[selectedNode.cp].concepts[selectedNode.ck].locations" :key="'locck' + i">{{ loc }}</div>
              </template>
              <template v-else-if="selectedNode.cp && !selectedNode.ck">
                <div v-for="(loc, i) of concepts[selectedNode.cp].locations" :key="'loccp' + i">{{ loc }}</div>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import xml2js from 'xml2js'
import yaml from 'js-yaml'
import {Teatree, NodeToggle} from 'vue-teatree'

export default {
  name: 'Direct',
  components: {
    Teatree, NodeToggle
  },
  data() {
    return {
      requiredKeys: [
        'jcr:mixinTypes',
        'jcr:primaryType',
        'jcr:uuid',
        'lastPublication',
        'mgnl:activationStatus',
        'mgnl:lastActivated',
        'mgnl:lastActivatedBy',
        'mgnl:lastActivatedVersion',
        'mgnl:lastActivatedVersionCreated',
        'mgnl:lastModified',
        'mgnl:lastModifiedBy',
        'status'
      ],
      newConceptName: '',
      fileType: null,
      objects: [],
      selectedBranch: null,
      magnoliaFile: null,
      magnoliaData: {},
      concepts: {},
      tree: [],
      error: null,
      rootKeys: {},
      regionKeys: {},
      selectedNode: {}
    }
  },
  computed: {
    branches() {
      let branches = []
      for (let o of this.objects) {
        branches.push({name: o.Key[0].substr(7).split('.')[0], file: o.Key[0], date: o.LastModified[0]})
      }
      return branches.sort((a, b) => {
        const d1 = new Date(a.date)
        const d2 = new Date(b.date)
        return d2 - d1
      }).splice(0, 10)
    }
  },
  watch: {
    magnoliaData() {
      this.combineData()
      this.calculateTree()
    },
    selectedBranch() {
      this.loadFile()
    }
  },
  mounted() {
    axios.get('https://dibh-dev-bobcatdirect-translations.s3.amazonaws.com/').then(r => {
      if (r.status === 200) {
        xml2js.parseString(r.data, function (err, result) {
          this.objects = result.ListBucketResult.Contents.slice(1)
        }.bind(this))
      }
    })
  },
  methods: {
    addNewConcept() {
      let leaf = {name: this.newConceptName, show: true, showChildren: false, children: [], data: {variant: 'danger', missing: !this.selectedNode.variable, variable: !!this.selectedNode.variable, ck: this.newConceptName, cp: this.selectedNode.cp, keys: {}}}
      for (const regionName in this.languages) {
        leaf.data[regionName] = {}
        for (const lang of this.languages[regionName]) {
          leaf.data[regionName][lang.magnoliaSuffix] = {content: '', type: 'PlainText'}
        }
      }
      this.newConceptName = ''
      const branch = this.tree.find(b => {
        return b.name === this.selectedNode.cp
      })
      branch.children.push(leaf)
      branch.showChildren = true
    },
    deleteSelectedNode() {
      this.$set(this.selectedNode, 'delete', !this.selectedNode.delete)
    },
    combineData() {
      this.tree = []
      let data = {}
      for(const cp of Object.keys(this.magnoliaData).sort()) {
        data[cp] = JSON.parse(JSON.stringify(this.magnoliaData[cp]))
      }
      for(const cp of Object.keys(this.concepts).sort()) {
        data[cp] = data[cp] || {concepts: {}}
        for(const ck of Object.keys(this.concepts[cp].concepts).sort()) {
          data[cp].concepts[ck] = data[cp].concepts[ck] || {}
          Object.assign(data[cp].concepts[ck], this.concepts[cp].concepts[ck])
        }
      }
      //sort and assign to tree
      for(const cp of Object.keys(data).sort()) {
        let branch = {name: cp, show: true, showChildren: false, children: [], data: {variant: 'success', missing: !this.concepts[cp], variable: this.concepts[cp]?.variable, cp: cp, keys: data[cp].keys}}
        for(const ck of Object.keys(data[cp].concepts).sort()) {
          const missing = !(this.concepts[cp] && this.concepts[cp].concepts[ck])
          const variable = this.concepts[cp]?.variable && !this.concepts[cp].concepts[ck]
          let leaf = {name: ck, show: true, showChildren: false, children: [], data: {variant: 'success', missing: missing, variable, ck: ck, cp: cp, keys: data[cp].concepts[ck].keys}}
          if (missing) {
            branch.data.containMissing = true
          }
          if (data[cp].concepts[ck] === undefined || !data[cp].concepts[ck].content || data[cp].concepts[ck].content === '') {
            branch.data.variant = 'danger'
            leaf.data.variant = 'danger'
            leaf.data.content = ''
            leaf.data.type = 'PlainText'
          } else {
            leaf.data = Object.assign(leaf.data, data[cp].concepts[ck])
          }
          branch.children.push(leaf)
        }
        this.tree.push(branch)
      }
    },
    calculateTree() {
      for(let branch of this.tree) {
        branch.data.variant = 'success'
        for(let leaf of branch.children) {
          leaf.data.variant = 'success'
          if (leaf.data.content === '') {
            branch.data.variant = 'danger'
            leaf.data.variant = 'danger'
          }
        }
      }
    },
    selectBranch(b) {
      this.selectedBranch = b
    },
    selectNode(node) {
      this.selectedNode = node.data
    },
    loadFile() {
      if (this.selectedBranch) {
        axios.get('https://dptranslation.dev.dice-tools.com/' + this.selectedBranch.file).then(r => {
          if (r.status === 200) {
            this.concepts = r.data
            this.combineData()
            this.calculateTree()
          }
        })
      }
    },
    generateKeys(base, values) {
      if (!values) values = {}
      for (const key of this.requiredKeys) {
        switch(key) {
        case 'jcr:mixinTypes': base[key] = values[key] || ['mgnl:hasVersion']//create if does not exist
          break
        case 'mgnl:lastModified': base[key] = values[key] || new Date() //create if does not exist
          break
        case 'mgnl:lastModifiedBy': base[key] = values[key] || 'DP Translation Tool' //create if does not exist
          break
        default: if (values[key]) base[key] = values[key] //copy if exist
        }
      }
      return base
    },
    loadMagnoliaFile() {
      this.magnoliaData = {}
      if (this.magnoliaFile) {
        const fileExt = this.magnoliaFile.name.split('.').pop()
        if (fileExt !== 'yaml') {this.error = 'Export file must be .yaml'; return}
        let read = new FileReader()
        read.readAsText(this.magnoliaFile, 'UTF-8')
        read.onloadend = () => {
          yaml.loadAll(read.result, function (doc) {
            const rootName = 'components'
            let data = {}
            this.rootKeys = this.generateKeys({}, doc[rootName])
            for(const folder in doc[rootName]) {
              const prefix = folder.split(':').shift()
              if (['jcr', 'mgnl'].includes(prefix)) continue
              data[folder] = data[folder] || {keys: {}, concepts: {}}
              data[folder].keys = this.generateKeys(data[folder].keys || {}, doc[rootName][folder])
              for (const conceptName in doc[rootName][folder]) {
                const prefix = conceptName.split(':').shift()
                if (['jcr', 'mgnl'].includes(prefix)) continue
                data[folder].concepts[conceptName] = data[folder].concepts[conceptName] || {keys: {}}
                data[folder].concepts[conceptName] = data[folder].concepts[conceptName] || {}
                data[folder].concepts[conceptName].keys = this.generateKeys(data[folder].concepts[conceptName].keys || {}, doc[rootName][folder][conceptName])


                if (doc[rootName][folder][conceptName].value === 'PlainText') {
                  data[folder].concepts[conceptName].content = doc[rootName][folder][conceptName].valuePlainText
                  data[folder].concepts[conceptName].type = 'PlainText'
                  continue
                }
                if (doc[rootName][folder][conceptName].value === 'RichText') {
                  data[folder].concepts[conceptName].content = doc[rootName][folder][conceptName].valueRichText
                  data[folder].concepts[conceptName].type = 'RichText'
                  continue
                }
                data[folder].concepts[conceptName].content = doc[rootName][folder][conceptName].valuePlainText
                data[folder].concepts[conceptName].type = 'PlainText'
              }
            }
            this.magnoliaData = Object.assign({}, data)
          }.bind(this))
        }
      }
    },
    exportToFile() {
      let rootName = 'components'
      let yamlObject = {}
      yamlObject[rootName] = this.rootKeys
      for (let branch of this.tree) {
        if (branch.data.delete) continue
        if (!branch.children.length) continue
        let branchName = branch.name.replace('/', '_')
        yamlObject[rootName][branchName] = this.generateKeys({'jcr:primaryType': 'mgnl:folder'}, branch.data.keys)
        for (let leaf of branch.children) {
          if (leaf.data.delete) continue
          yamlObject[rootName][branchName][leaf.name] = this.generateKeys({'jcr:primaryType': 'mgnl:content'}, leaf.data.keys)
          const type = leaf.data.type
          const valueLabel = 'value'
          const typeLabel = 'value' + type
          yamlObject[rootName][branchName][leaf.name][valueLabel] = type
          yamlObject[rootName][branchName][leaf.name][typeLabel] = leaf.data.content !== '' ? leaf.data.content : ''
        }
      }
      let yamlFile = yaml.dump(yamlObject, {forceQuotes: true, quotingType: '\'', noRefs: true})
      let element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(yamlFile))
      element.setAttribute('download', 'kags_concepts.yaml')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }
}
</script>

